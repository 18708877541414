import Config from '@/config';
import router from '@/router';
import timelineAPIConstructor from '@/api/document/document-api-timeline';

const timelineAPI = timelineAPIConstructor('nl');

// initial state
const state = {
  nlSearchMoreAfter: null,

  nlTimelineData: {
    data: [],
    countTotal: 0,
    hasMore: false,
  },
  nlMaxDateRangeTimelineItemsTotal: 0,
};

// getters
const getters = {
  nlSearchMoreAfter: (state) => state.nlSearchMoreAfter,
  nlTimelineItems: (state) => state.nlTimelineData.data,
  nlTimelineItemsCountTotal: (state) => state.nlTimelineData.countTotal,
  nlTimelineItemsHasMore: (state) => state.nlSearchMoreAfter
    && (state.nlTimelineData.data.length < state.nlTimelineData.countTotal),
  nlMaxDateRangeTimelineItemsHasMore: (state) => state.nlNextItemUrl
      && (state.nlTimelineData.data.length < state.nlMaxDateRangeTimelineItemsTotal),
  nlMaxDateRangeTimelineItemsTotal: (state) => state.nlMaxDateRangeTimelineItemsTotal,
};

// actions
const actions = {
  nlSetMaxDateRangeTimelineItemsTotal({ commit }, total) {
    commit('nlSetMaxDateRangeTimelineItemsTotal', total);
  },

  nlSetSearchMoreAfter({ commit }, url) {
    commit('nlSearchMoreAfter', url);
  },

  // Although getEntityByUrl is asynchronous, it needs to be fired synchronous since the
  // items are sorted by time.
  async nlGetAllTimelineItemsNext({ state, commit, rootState }) {
    // Check if searchMoreAfter is available
    if (state.nlSearchMoreAfter) {
      const { searchParameters } = rootState;
      const response = await timelineAPI.getTimelineItems(
        { ...searchParameters, searchAfter: state.nlSearchMoreAfter, contextRequired: true },
      );
      if (!response) {
        commit('setMessage', {
          message: 'Er is iets mis gegaan. Als dit probleem zich blijft voordoen neem dan contact met ons op.',
          type: 'info',
        });
        return;
      }
      if (response.data.countTotal > 0 && response.data.searchMoreAfter) {
        commit('nlSetSearchMoreAfter', response.data.searchMoreAfter);
        commit('nlAppendTimelineItems', response.data);
      }
    }
  },

  async nlGetAllTimelineItems({ commit, rootState }, skipHistory) {
    // Let's see how many times we have been called
    const { searchParameters } = rootState;
    const {
      query, minimalMatchPercentage, context, groupingDetails, groupPaths,
    } = searchParameters;
    commit('nlClearTimelineItems');

    // Set lookBack to false to avoid button will be drawn when switching to another date range
    this.dispatch('setLookBack', false);

    // Validate a query has been set.
    if (!query) {
      commit('setMessage', {
        message: 'Geef een onderwerp op',
        type: 'info',
      }, { root: true });

      return;
    }

    // Validate a query has been set.
    if (query.length < Config.minimalQueryLength) {
      commit('setMessage', {
        message: `Onderwerp moet uit minimaal ${Config.minimalQueryLength} karakters bestaan`,
        type: 'info',
      }, { root: true });

      return;
    }

    // Validate a context has been set.
    if (router.currentRoute.name === 'nlDashboard' && context.length === 0) {
      commit('setMessage', {
        message: 'Selecteer een context',
        type: 'info',
      }, { root: true });
      return;
    }

    if (router.currentRoute.name === 'nlDashboard' && !skipHistory) {
      // Add query to recent searches
      commit('nlAddRecentSearch', {
        queryDetails: {
          query,
          minimalMatchPercentage,
          context,
          groupingDetails,
          groupPaths,
        },
      }, { root: true });
      // Set current search to null so none of the saved searches are shown as the active search
      commit('nlResetCurrentSearch');
    }

    const response = await timelineAPI.getTimelineItems(
      { ...searchParameters, contextRequired: true, includeFullHistoryCount: true },
    );
    if (!response) {
      commit('setMessage', {
        message: 'Er is iets mis gegaan. Als dit probleem zich blijft voordoen neem dan contact met ons op.',
        type: 'info',
      });
      return;
    }
    commit('nlSetTimelineItems', response.data);

    if (response.data.searchMoreAfter) {
      commit('nlSetSearchMoreAfter', response.data.searchMoreAfter);
    }
    if (response.data.fullHistoryCount) {
      commit('nlSetMaxDateRangeTimelineItemsTotal', response.data.fullHistoryCount);
    }

    // This should only be done in dashboard
    if (router.currentRoute.name === 'nlDashboard') {
      // Fetch agendas for the documents
      // We need agenda items in the timeline only if a user has no access authentication to agenda
      // TODO: Fix me!!!
      // Possible fix for POLMONNL-2117
      // this.dispatch('setAgendaStartDate', new Date().getTime());
      // this.dispatch('setAgendaEndDate', new Date(new Date().setDate(new Date().getDate() + 90)).getTime());
      this.dispatch('setAgendaStartDate', '');
      this.dispatch('setAgendaEndDate', '');
      this.dispatch('setAgendaFilters', []);
      this.dispatch('setAgendaQuery', query);
      this.dispatch('getAllAgendas');

      // Fetch trend, stakeholders and trending topics
      this.dispatch('nlGetStatistics');
    }

    if (response.data.countTotal === 0) {
      this.dispatch('setLookBack', true);
      commit('setMessage', {
        message: 'Geen resultaten gevonden',
        type: 'info',
      });
    }
  },

  async nlClearTimelineItems({ commit }) {
    commit('nlClearTimelineItems');
  },
};

// mutations
const mutations = {
  nlSetMaxDateRangeTimelineItemsTotal(state, total) {
    state.nlMaxDateRangeTimelineItemsTotal = total;
  },

  nlSetSearchMoreAfter(state, searchMoreAfter) {
    state.nlSearchMoreAfter = searchMoreAfter;
  },

  nlSetTimelineItems(state, timelineData) {
    state.nlTimelineData = timelineData;
    state.nlTimelineData.hasMore = !!((timelineData.actions && timelineData.actions.more));
  },

  nlAppendTimelineItems(state, timelineData) {
    state.nlTimelineData.data = [...state.nlTimelineData.data, ...timelineData.data];
  },

  nlClearTimelineItems(state) {
    state.nlTimelineData = {
      data: [],
      countTotal: 0,
      hasMore: false,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
