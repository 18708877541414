/*
 * Store to manage the state of stored search queries
 */

import Config from '@/config';
import savedSearchesApiConstructor from '@/api/document/document-api-searches';

const savedSearchesApi = savedSearchesApiConstructor('nl');

// initial state
const state = {
  // Holds all searches made by the user, this is the "search history"
  nlAllRecentSearches: [],

  // Holds all searches saved by the user on the server
  nlAllSavedSearches: [],

  nlMaxNumberOfSearches: null,

  // This is the currently selected search, could be recent or saved
  // Is used to highlight the active search in the list (via its id)
  nlCurrentSearch: {
    id: null,
    title: null,
    description: null,
    sendUpdatesPerEmail: {
      daily: false,
      realTime: false,
    },
    rssEnabled: false,
    queryDetails: {
      context: [],
      groupPaths: [],
      groupingDetails: {
        name: null,
        version: null,
      },
      minimalMatchPercentage: 0,
      query: null,
      metadataField: 'metadata',
    },
  },
};

// getters
const getters = {
  nlAllRecentSearches: (state) => state.nlAllRecentSearches,
  nlAllSavedSearches: (state) => state.nlAllSavedSearches,
  nlCurrentSearch: (state) => state.nlCurrentSearch,
  nlMaxNumberOfSearches: (state) => state.nlMaxNumberOfSearches,
};

// actions
const actions = {
  // Next line is absolutely necessary
  // eslint-disable-next-line no-unused-vars
  async nlGetAllSavedSearches({ state, commit, rootState }, payload) {
    // TODO: Add 'shortDomainType' derived from router name 'nl' or 'eu'
    const response = await savedSearchesApi.getAll({ groupingName: 'nlFiltersGrouping' });
    commit('nlSetSavedSearches', response.data);
    commit('nlMaxNumberOfSearches', response.data.numberOfSearches);
    return response;
  },

  async nlSaveSearch(_, search) {
    let response;
    // If an id is provided the search is already stored on the server
    if (search.id) {
      response = await savedSearchesApi.update(search);
    } else {
      response = await savedSearchesApi.save(search);
    }

    // Refresh the saved searches list with the data on the server to make sure everything between
    // server and client is in sync
    this.dispatch('nlGetAllSavedSearches');
    return response;
  },

  async nlSaveSearches(_, search) {
    // Update multiple searches at once
    let response;
    try {
      response = await savedSearchesApi.updates(search);
    } finally {
      // Refresh the saved searches list with the data on the server to make sure everything between
      // server and client is in sync
      this.dispatch('nlGetAllSavedSearches');
    }

    return response;
  },

  // search is the current selected saved search object
  async nlUpdateSearch({ state, rootState, commit }, search) {
    // We are going to update the current active saved search with the current searchparameters
    // This is only allowed in a mutation, therefore we use syncCurrentSearch
    commit('nlSyncCurrentSearch', { search, searchParameters: rootState.searchParameters });

    // Store the merged currentSearch on the server
    const response = await savedSearchesApi.update(state.nlCurrentSearch);

    this.dispatch('nlGetAllSavedSearches');
    return response;
  },

  async nlRemoveSearch(_, search) {
    const response = await savedSearchesApi.delete(search);
    this.dispatch('nlGetAllSavedSearches');
    return response;
  },

  async nlSetCurrentSearch({ commit }, search) {
    commit('nlSetCurrentSearch', search);
  },

  nlResetCurrentSearch({ commit }) {
    commit('nlResetCurrentSearch');
  },

  async nlSetRecentSearches({ commit }, searches) {
    commit('nlSetRecentSearches', searches);
  },
};

// mutations
const mutations = {
  nlSetCurrentSearch(state, search) {
    state.nlCurrentSearch = search;
  },
  nlSetSavedSearches(state, searches) {
    // Reverse order of array because we want to draw the latest item on top.
    searches.data.reverse();
    state.nlAllSavedSearches = searches;
  },
  nlAddSavedSearch(state, search) {
    state.nlAllSavedSearches.data.unshift(search);
  },
  nlSetRecentSearches(state, searches) {
    state.nlAllRecentSearches = searches;
  },
  nlMaxNumberOfSearches(state, payload) {
    state.nlMaxNumberOfSearches = payload;
  },
  nlAddRecentSearch(state, search) {
    // Context and document types are sorted to avoid duplicates
    // when we are creating hashes in the future to detect if a search is
    // really new\
    const recentSearch = search;
    const sortedQueryDetails = {
      query: recentSearch.queryDetails.query,
      context: recentSearch.queryDetails.context.sort(),
      groupPaths: recentSearch.queryDetails.groupPaths.sort(),
      groupingDetails: recentSearch.queryDetails.groupingDetails,
      minimalMatchPercentage: recentSearch.queryDetails.minimalMatchPercentage,
    };

    recentSearch.queryDetails = sortedQueryDetails;

    // Add to the top
    state.nlAllRecentSearches.unshift(recentSearch);

    if (state.nlAllRecentSearches.length >= Config.maxRecentSearches) {
      // Remove last item
      state.nlAllRecentSearches.splice(-1, 1);
    }
  },
  nlSyncCurrentSearch(state, { searchParameters }) {
    state.nlCurrentSearch.queryDetails = searchParameters;
    state.nlCurrentSearch.queryDetails.query = searchParameters.query;
  },
  nlResetCurrentSearch(state) {
    state.nlCurrentSearch = {
      id: null,
      title: null,
      description: null,
      sendUpdatesPerEmail: {
        daily: false,
        realTime: false,
      },
      rssEnabled: false,
      queryDetails: {
        context: [],
        groupPaths: [],
        groupingDetails: {
          name: null,
          version: null,
        },
        minimalMatchPercentage: 0,
        query: null,
        metadataField: 'metadata',
      },
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
