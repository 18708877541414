// App specific
import Config from '@/config';
import statisticsAPIConstructor from '@/api/document/document-api-statistics';

const statisticsAPI = statisticsAPIConstructor('nl');

// initial state
const state = {
  nlStatisticsTrend: null,
  nlStatisticsStakeholders: [],
  nlStatisticsTrendingTopics: [],
};

// getters
const getters = {
  nlStatisticsTrend: (state) => state.nlStatisticsTrend,
  nlStatisticsStakeholders: (state) => state.nlStatisticsStakeholders,
  nlStatisticsTrendingTopics: (state) => state.nlStatisticsTrendingTopics,
};

// actions
const actions = {
  /**
   * Retrieves all statistics
   *
   * @param {any} { state, commit, rootState }
   */
  async nlGetStatistics({ commit, rootState }, stakeholderCode) {
    let code = stakeholderCode;
    // Get the currently set searchParameters
    const { searchParameters } = rootState;

    // Add statistics grouping to use
    searchParameters.trendsGroupingName = 'nlTrendsGrouping';

    const responseTrend = await statisticsAPI.getTrend(searchParameters);
    commit('nlSetTrend', responseTrend.data);

    if (code) {
      localStorage.setItem('stakeholderCode', code);
    } else {
      code = localStorage.getItem('stakeholderCode') || Config.nlDefaultAnalyticsCode;
    }

    const responseStakeholders = await statisticsAPI
      .nlGetStakeholders(searchParameters, code);

    commit('nlSetStakeholders', responseStakeholders.data.data);

    const responseTrendingTopics = await statisticsAPI.getTrendingTopics(searchParameters);

    commit('nlSetTrendingTopics', responseTrendingTopics.data.data);
  },

  async nlClearStatistics({ commit }) {
    commit('nlClearStatistics');
  },

  async getAllNLandLRStatistics({ rootState }) {
    const { searchParameters } = rootState;
    return statisticsAPI.getAllNLandLRStatistics(searchParameters);
  },
};

// mutations
const mutations = {
  nlSetTrend(state, trend) {
    state.nlStatisticsTrend = trend;
  },
  nlSetStakeholders(state, stakeholders) {
    state.nlStatisticsStakeholders = stakeholders;
  },
  nlSetTrendingTopics(state, trendingTopics) {
    state.nlStatisticsTrendingTopics = trendingTopics;
  },
  nlClearStatistics(state) {
    state.nlStatisticsStakeholders = [];
    state.nlStatisticsTrendingTopics = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
