<template>
  <v-app
    id="app"
    hidden
  >
    <v-main :class="!this.$vuetify.theme.dark ? 'grey lighten-3' : ''">
      <router-view class="mt-n1" />
      <support-dialog />
      <confirm-dialog />
    </v-main>
  </v-app>
</template>

<script>
import SupportDialog from '@/components/SupportDialog.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {

  $el: '#app',

  components: {
    SupportDialog,
    ConfirmDialog,
  },

  async mounted() {
    // Get theme from preferences or get it from localStorage if not available
    this.$vuetify.theme.dark = await this.$store.getters.userPreferences.uiDarkMode || JSON.parse(localStorage.getItem('uiDarkMode'));

    this.$nextTick(() => {
      this.$el.removeAttribute('hidden');
    });
  },

};
</script>

<style lang="scss"> // Carefull, block not scoped
// These are custom overrides for issue POLMONNL-2816
// Unfortunately some overrides can't be done in variables.scss.
// This is because they were overridden in the components where they are used

@media only screen and (max-width: 1904px) {
  .treeview .checkboxLabel {
    font-size: 12.4px !important;
  }
  .v-label {
    font-size: 14.4px !important;
  }
  .apexcharts-legend-text {
    font-size: 11px !important;
  }
}
</style>
